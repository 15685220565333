var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"list px-3 mx-auto",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-data-table',{staticClass:"iconTable",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.users.rows,"disable-pagination":"","hide-default-footer":true,"loading":_vm.fetchingData,"loading-text":'Cargando... Espere por favor',"no-data-text":'datos no disponibles',"item-key":"_id","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_vm._l((headers),function(header){return [_c('th',{key:header.value,class:header.align},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticStyle:{"color":"#000"}},[_vm._v(" "+_vm._s(header.text)+" "),(header.text !== 'Detalle')?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-arrow-down-drop-circle ")]):_c('v-icon')],1)])],1)],1)]})],2)])]}},{key:"item.names",fn:function(ref){
var item = ref.item;
return [_c('td',{style:(_vm.users.rows.indexOf(item) % 2 == 0
              ? 'background:#eeee!important'
              : '')},[_vm._v(" "+_vm._s(item.names)+" ")])]}},{key:"item.last_name",fn:function(ref){
              var item = ref.item;
return [_c('td',{style:(_vm.users.rows.indexOf(item) % 2 == 0
              ? 'background:#eeee!important'
              : '')},[_vm._v(" "+_vm._s(item.last_name)+" ")])]}},{key:"item.email",fn:function(ref){
              var item = ref.item;
return [_c('td',{style:(_vm.users.rows.indexOf(item) % 2 == 0
              ? 'background:#eeee!important'
              : '')},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.photo_url",fn:function(ref){
              var item = ref.item;
return [_c('td',{style:(_vm.users.rows.indexOf(item) % 2 == 0
              ? 'background:#eeee!important'
              : '')},[(item.photo_url)?_c('div',{staticClass:"p-2 mt-1 mb-1"},[_c('v-img',{attrs:{"src":item.photo_url,"alt":item.name,"width":"70px"}})],1):_c('div',{staticClass:"p-2 mt-1 mb-1"},[_c('v-img',{attrs:{"src":require("@/assets/images/person.jpg"),"alt":"","width":"70px"}})],1)])]}},{key:"item.role",fn:function(ref){
              var item = ref.item;
return [_c('td',{style:(_vm.users.rows.indexOf(item) % 2 == 0
              ? 'background:#eeee!important'
              : '')},[_vm._v(" "+_vm._s(item.role)+" ")])]}},{key:"item.nice_status",fn:function(ref){
              var item = ref.item;
return [_c('td',{style:(_vm.users.rows.indexOf(item) % 2 == 0
              ? 'background:#eeee!important'
              : '')},[_vm._v(" "+_vm._s(item.nice_status)+" ")])]}},{key:"item.detail",fn:function(ref){
              var item = ref.item;
return [_c('td',{style:(_vm.users.rows.indexOf(item) % 2 == 0
              ? 'background:#eeee!important'
              : '')},[_c('v-btn',{attrs:{"icon":"","to":'/usuarios/edit/' + item.id}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.pageSizes,"label":"Items per Page"},on:{"change":_vm.handlePageSizeChange},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('v-pagination',{attrs:{"length":_vm.users.totalPages,"total-visible":"7","next-icon":"mdi-menu-right","prev-icon":"mdi-menu-left"},on:{"input":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }