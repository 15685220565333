<template>
  <v-card height="100%" tile class="pa-10">
    <v-card-text class="pa-1">
      <v-container class="pa-2" fluid>
        <v-row dense>
          <v-col cols="12">
            <v-card>
              <v-card-title class="pa-2">
                <v-row dense>
                  <v-spacer />
                  <v-btn
                    dense
                    color="#479b25"
                    dark
                    class="ma-3"
                    :to="{ name: 'user-add' }"
                  >
                    Crear nuevo usuario
                  </v-btn>
                </v-row>
              </v-card-title>
              <v-divider />
              <v-card-text class="pa-0">
                <table-user></table-user>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import TableUser from "@/components/tables/TableUser.vue";
export default {
  name: "users-index",
  components: { TableUser },
  data: () => ({}),
};
</script>

<style scoped>
</style>