<template>
  <v-row align="center" class="list px-3 mx-auto">
    <v-col cols="12" sm="12">
      <v-data-table
        :mobile-breakpoint="0"
        class="iconTable"
        :headers="headers"
        :items="users.rows"
        disable-pagination
        :hide-default-footer="true"
        :loading="fetchingData"
        :loading-text="'Cargando... Espere por favor'"
        :no-data-text="'datos no disponibles'"
        item-key="_id"
        hide-default-header
      >
        <template #header="{ props: { headers } }">
          <thead class="v-data-table-header">
            <tr>
              <template v-for="header in headers">
                <th :key="header.value" :class="header.align">
                  <v-row>
                    <v-col cols="12">
                      <h2 style="color: #000">
                        {{ header.text }}
                        <v-icon
                          v-if="header.text !== 'Detalle'"
                          color="primary"
                        >
                          mdi-arrow-down-drop-circle
                        </v-icon>
                        <v-icon v-else />
                      </h2>
                    </v-col>
                  </v-row>
                </th>
              </template>
            </tr>
          </thead>
        </template>
        <template #[`item.names`]="{ item }">
          <td
            :style="
              users.rows.indexOf(item) % 2 == 0
                ? 'background:#eeee!important'
                : ''
            "
          >
            {{ item.names }}
          </td>
        </template>
        <template #[`item.last_name`]="{ item }">
          <td
            :style="
              users.rows.indexOf(item) % 2 == 0
                ? 'background:#eeee!important'
                : ''
            "
          >
            {{ item.last_name }}
          </td>
        </template>
        <template #[`item.email`]="{ item }">
          <td
            :style="
              users.rows.indexOf(item) % 2 == 0
                ? 'background:#eeee!important'
                : ''
            "
          >
            {{ item.email }}
          </td>
        </template>
        <template v-slot:[`item.photo_url`]="{ item }">
          <td
            :style="
              users.rows.indexOf(item) % 2 == 0
                ? 'background:#eeee!important'
                : ''
            "
          >
            <div class="p-2 mt-1 mb-1" v-if="item.photo_url">
              <v-img
                :src="item.photo_url"
                :alt="item.name"
                width="70px"
              ></v-img>
            </div>
            <div class="p-2 mt-1 mb-1" v-else>
              <v-img
                src="@/assets/images/person.jpg"
                alt=""
                width="70px"
              ></v-img>
            </div>
          </td>
        </template>
        <template #[`item.role`]="{ item }">
          <td
            :style="
              users.rows.indexOf(item) % 2 == 0
                ? 'background:#eeee!important'
                : ''
            "
          >
            {{ item.role }}
          </td>
        </template>
        <template #[`item.nice_status`]="{ item }">
          <td
            :style="
              users.rows.indexOf(item) % 2 == 0
                ? 'background:#eeee!important'
                : ''
            "
          >
            {{ item.nice_status }}
          </td>
        </template>
        <template #[`item.detail`]="{ item }">
          <td
            :style="
              users.rows.indexOf(item) % 2 == 0
                ? 'background:#eeee!important'
                : ''
            "
          >
            <v-btn icon :to="'/usuarios/edit/' + item.id">
              <v-icon small class="mr-2"> mdi-pencil </v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </v-col>

    <v-col cols="12" sm="12">
      <v-row>
        <v-col cols="4" sm="3">
          <v-select
            v-model="pageSize"
            :items="pageSizes"
            label="Items per Page"
            @change="handlePageSizeChange"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="9">
          <v-pagination
            v-model="page"
            :length="users.totalPages"
            total-visible="7"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
            @input="handlePageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "table-user",
  watch: {
    text: function (value) {
      this.getFetch();
    },
  },
  data: () => ({
    headers: [
      { text: "Nombre", value: "names", width: 150 },
      { text: "Apellidos", value: "last_name", width: 200 },
      { text: "Correo", value: "email" },
      { text: "Foto", value: "photo_url", sortable: false },
      { text: "Rol", value: "role" },
      { text: "Estado", value: "nice_status" },
      { text: "Accion", value: "detail", sortable: false },
    ],
    page: 1,
    totalPages: 0,
    pageSizes: [10, 20, 30],
    pageSize: 10,
    //   loading: false
  }),
  computed: {
    ...mapState({
      users: (state) => state.user.users,
      fetchingData: (state) => state.fetchingData,
    }),
    text() {
      return this.$route.query.text;
    },
  },
  methods: {
    ...mapActions(["fetchUsers"]),
    getRequestParams(page, pageSize) {
      let params = {};

      if (this.text) {
        params["text"] = this.text;
      }
      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }
      return params;
    },
    handlePageChange(value) {
      this.page = value;
      this.getFetch();
      //this.retrieveTutorials();
    },

    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.getFetch();
      //this.retrieveTutorials();
    },
    getFetch() {
      const params = this.getRequestParams(this.page, this.pageSize);
      this.fetchUsers({ params: params });
    },
    getCurrentYear() {
      return new Date().getFullYear();
    },
  },
  created() {
    this.getFetch();
  },
};
</script>

<style>
</style>